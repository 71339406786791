
import { Component, Vue, Watch } from "vue-property-decorator";
import * as FactcheckServices from '@/services/FactCheckService';
import * as ClaimCategoryService from "@/services/ClaimCategoryService";
import { ClaimCategory, FactcheckFilter } from "@/types";


@Component
export default class Index extends Vue {
    headers = [
        { text: 'Titel', value: 'title' },
        { text: 'Partner', value: 'partner' },
        { text: 'Categorieen', value: 'categories' },
    ]

    selected = [] as Array<{ title: string, partner: string, categories: string, id: string, claimId: string, categoriesIds: string[] }>
    data = [] as Array<{ title: string, partner: string, categories: string }>
    categories: ClaimCategory[] = [];

    loading = {
        factchecks: false,
        move: false,
        delete: false,
        add: false,
    }

    dialogs = {
        move: false,
        add: false,
        delete: false,
    }


    progress = 0;
    selectedCategory = '';
    selectedMoveCategory = '';
    selectedDeleteCategory = '';
    selectedAddCategory = '';

    @Watch('selectedCategory')
    async onSelectedCategoryChanged() {
        this.loading.factchecks = true;
        if (this.selectedCategory === '') return;
        const data = await FactcheckServices.GetAllFactChecks({
            category: this.selectedCategory
        } as FactcheckFilter);

        this.data = data.map(x => ({
            id: x.id,
            title: x.title,
            claimId: x.ClaimId,
            partner: x.Claim?.ClaimReviews.map(e => e.Partner?.name).join(', ') ?? '',
            categories: x.Claim?.ClaimCategories?.map(x => x.nl).join(', ') ?? '',
            categoriesIds: x.Claim?.ClaimCategories?.map(x => x.id) ?? [],
        }));
        this.loading.factchecks = false;
        this.selected = [];
    }

    async mounted() {
        this.categories = await ClaimCategoryService.GetClaimCategories();
    }

    async submitMove() {
        const moveTo = this.categories.find(x => x.id === this.selectedMoveCategory);

        if (!moveTo) return;

        this.loading.move = true;
        this.progress = 0;
        let index = 0;
        for (let selected of this.selected) {
            let arrayList = selected.categoriesIds;
            arrayList.splice(arrayList.indexOf(this.selectedCategory), 1);
            arrayList.push(moveTo.id);
            console.log(arrayList);
            await FactcheckServices.SetClaimCategories(selected.claimId, arrayList)
            this.progress = Math.floor((index + 1) / this.selected.length * 100);
            index++;
        }
        await this.onSelectedCategoryChanged();
        this.$forceUpdate();
        this.loading.move = false;
        this.dialogs.move = false;
    }

    async submitAdd() {
        const newCat = this.categories.find(x => x.id === this.selectedAddCategory);

        this.loading.add = true;
        this.progress = 0;
        let index = 0;

        for (let selected of this.selected) {
            let arrayList = selected.categoriesIds;
            arrayList.push(newCat!.id);
            await FactcheckServices.SetClaimCategories(selected.claimId, arrayList)

            this.progress = Math.floor((index + 1) / this.selected.length * 100);
            index++;
        }
        await this.onSelectedCategoryChanged();
        this.$forceUpdate();
        this.loading.add = false;
        this.dialogs.add = false;
    }

    async submitDelete() {
        const deleteCat = this.categories.find(x => x.id === this.selectedDeleteCategory);

        this.loading.delete = true;
        this.progress = 0;
        let index = 0;

        for (let selected of this.selected) {
            let arrayList = selected.categoriesIds;
            if (arrayList.includes(deleteCat!.id)) {
                arrayList.splice(arrayList.indexOf(deleteCat!.id), 1);
                await FactcheckServices.UpdateClaim({
                    id: selected.claimId,
                    ClaimCategories: arrayList.map(e => this.categories.find(x => x.id === e)!).map(x => {
                        return {
                            id: x.id,
                            nl: x.nl,
                        }
                    })
                })
            }

            this.progress = Math.floor((index + 1) / this.selected.length * 100);
            index++;
        }
        await this.onSelectedCategoryChanged();
        this.$forceUpdate();
        this.loading.delete = false;
        this.dialogs.delete = false;
    }
}
